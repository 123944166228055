<template>
  <label
    v-if="toggle"
    class="inline-flex items-center cursor-pointer"
    :class="{
      'cursor-not-allowed opacity-75': disabled,
    }"
  >
    <slot />
    <div class="relative inline-flex items-center">
      <input
        :id="id"
        type="checkbox"
        :checked="checked"
        @change="
          emit('update:checked', ($event.target as HTMLInputElement).checked)
        "
        :disabled="disabled"
        class="sr-only peer"
      />
      <div
        class="w-11 h-6 bg-gray-600 peer-focus:outline-none rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white peer-focus-within:after:bg-gradient-radial after:from-blue after:from-15% after:to-15% after:to-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue"
      ></div>
    </div>
  </label>

  <div v-else>
    <label class="checkbox">
      <input
        :id="id"
        :type="type"
        :required="required"
        :name="name"
        :checked="checked"
        @change="
          emit('update:checked', ($event.target as HTMLInputElement).checked)
        "
        :disabled="disabled"
      />
      <span class="checkmark" :class="addedClass" />
      <slot />
    </label>
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    id?: string
    type?: string
    name?: string
    required?: boolean
    checked?: boolean
    toggle?: boolean
    addedClass?: string | { [key: string]: boolean }
    disabled?: boolean
  }>(),
  {
    type: 'checkbox',
    name: 'checkbox',
    required: false,
    checked: false,
    disabled: false,
  }
)

const emit = defineEmits<{
  (e: 'update:checked', value: boolean): void
}>()
</script>

<style lang="scss" scoped>
.checkbox {
  display: inline-block;
  position: relative;
  padding-left: 36px;
  height: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover input ~ .checkmark {
    @apply bg-black bg-opacity-5;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      @apply bg-blue border-blue;
    }
    &:disabled ~ .checkmark {
      @apply bg-black bg-opacity-25 border-none;
    }
    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    @apply transition-all duration-100 border;
    &:after {
      content: '';
      position: absolute;
      display: none;
    }

    &:after {
      background-image: url(~/assets/icons/icon-action-check-white.svg);
      background-size: 18px 18px;
      background-repeat: no-repeat;
      background-position: center;
      width: 28px;
      height: 28px;
    }
  }
}
</style>
